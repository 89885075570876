// // basic imports
@import "styles/variables";
@import "styles/mixins/breakpoints";
@import "styles/mixins/animation";
@import "styles/mixins/utils";

// // resets, body, etc
* {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: $default-font-family;
    font-size: $default-font-size;
    font-weight: 400;
    color: $color-primary;
}

h1, h2, h3, h4 {
    font-weight: 700;
    margin: 20px 0;
}

h1 {
    font-weight: 700;
    font-size: 2.5em;
}

h2 {
    border-top: 1px solid $color-secondary;
    padding-top: 40px;
    margin-top: 40px;
    font-size: 2em;
}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.1em;
    margin-bottom: 10px;
}

code {
    border-radius: 20px;
    background: $color-background;
    padding: 20px;
    text-indent: 0;
    color: $color-primary;
    display: block;
    // width: 90%;
    margin: auto;
    overflow: scroll;
    width: 100%;
}

.wrapper {
    padding: 30px;

    .demo-gallery, .demo-slider, .demo-carousel {
        margin: 30px -30px;
    }

    .demo {
        display: block;
        margin: 30px 0;
        padding-bottom: 30px;
        border-bottom: 1px solid $color-primary;

        &:last-child {
            border-bottom: 0;
        }

    }
}

.list-items, .summary {
    margin-left: 50px;

    li {
        line-height: 2;
        list-style-type: circle;

        ul {
            margin-left: 30px;
        }
    }
}

%demo-box {
	margin: 50px auto;
}

.demo-gallery {
	@extend %demo-box;

	.thumb {
		width: 200px;
	}
}

.demo-slider {
	@extend %demo-box;
}

.demo-carousel {
	@extend %demo-box;
	width: 100%;
	margin: 30px auto!important;
	.thumb {
		width: 120px;
	}
}

.example {
	margin: 20px 0;
}

.parent {
	background: #333;
}

.page-carousel {
	padding: 40px;
	border: 1px solid #fff;
	width: 80%;
	margin: 40px auto;
}

h5 {
	margin-bottom: 10px;
}
